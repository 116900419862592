// toogle sidebar
export class Sidebar extends dk.Widget {

    construct() {
        this.sidebar = this.widget()[0];
        this.menu_items = this.sidebar.querySelectorAll('button', 'a');
        this.nav_divider = dk.$('.nav-divider');
        this.toggler = `<div class="sidebar-toggler">
                            <dk-icon value="chevron-down"></dk-icon>
                            <dk-icon value="chevron-down" class="bottom-chevron"></dk-icon>
                        </div>`;
        this.nav_divider.append(this.toggler);
        this.toggle = dk('.sidebar-toggler');

        this.close_sidebar();
        return this;
    }

    handlers() {
        this.toggle.addEventListener('click', () => this.toggle_sidebar());
        this.widget().on('click', 'button, a', () => this.close_sidebar());
        return this;
    }

    get open() {
        return localStorage.getItem('sidebar-collapsed') === 'open';
    }

    close_sidebar() {
        localStorage.setItem('sidebar-collapsed', 'closed');
        this.sidebar.classList.add('collapsed');
        this.toggle.style.rotate = '0deg'
    }

    open_sidebar() {
        localStorage.setItem('sidebar-collapsed', 'open');
        this.sidebar.classList.remove('collapsed');
        this.toggle.style.rotate = '180deg'
    }

    toggle_sidebar() {
        this.open ? this.close_sidebar() : this.open_sidebar();
    }
}
